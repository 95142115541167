<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <v-container fluid class="fill-height">
        <v-img
          lazy-src="logo"
          :src="logo"
          max-height="50"
          max-width="90"
        ></v-img>
        <v-spacer></v-spacer>
        <!-- <a v-bind:href="downloadUrl" class="link"> -->
        <v-btn depressed @click="trackDownload" color="white" class="btnBg"> Download</v-btn>
        <!-- </a> -->
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" class="dialogBg" depressed v-bind="attrs" v-on="on" >
              Contact Us
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline pl-2">Contact Us</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Full Name*"
                      v-model="nameString"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Email*"
                      v-model="emailString"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Leave us a Message*"
                      required
                      v-model="msgString"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <small class="pl-4">{{ infoMessage }}</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                Cancel
              </v-btn>
              <v-btn color="primary" text @click="sendEmail"> Send </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-container class="ma-0 pa-0" fluid>
        <template>
          <div :style="{ background: $vuetify.theme.themes.light.pink }">
            <div class="pa-16 gradient">
              <v-row justify="center" align="center">
                <v-img
                  lazy-src="header"
                  :src="header"
                  contain
                  max-height="600"
                ></v-img>
              </v-row>

              <h1 class="text-center font-weight-bold white--text mt-16 mb-2  lineHeight">
                fun activities for your mental health
              </h1>

              <p class="text-center">available on iOS and Android for free</p>

              <div class="">
                <v-row class="pa-2" justify="center" align="center" no-gutters>
                  <v-col cols="auto">
                    <a v-bind:href="appstoreUrl">
                      <v-img
                        min-width="180"
                        lazy-src="logo"
                        :src="appstore"
                        contain
                      ></v-img>
                    </a>
                  </v-col>
                  <v-col cols="auto">
                    <a v-bind:href="playstoreUrl">
                      <v-img
                        min-width="100"
                        max-width="230"
                        lazy-src="logo"
                        :src="playstore"
                        contain
                      ></v-img>
                    </a>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <!-- <v-container class="pa-8" fluid>
        <h2 class="text-center pink--text">What is Tappily?</h2>

        <p class="text-center pb-8">
          the app that brings positivity into your day.
        </p>

        <v-carousel
          height="800"
          light
          continuous
          interval="5000"
          hide-delimiter-background
        >
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
            contain
            touch
          ></v-carousel-item>
        </v-carousel>
      </v-container> -->

          <div class="pa-16">
            <h1 class="text-center font-weight-bold black--text pb-16 lineHeight">
              frequently asked questions
            </h1>

            <v-row justify="center">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="(item, i) in faq" :key="i">
                  <v-expansion-panel-header class="font-weight-bold">{{
                    item.question
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{ item.answer }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </div>

          <!-- <v-container class="pa-16" fluid
         :style="{ background: $vuetify.theme.themes.light.lightPink }">
        <h1 class="text-center font-weight-bold pink--text pt-16">
          download tappily for free today
        </h1>

        <p class="text-center">available on iOS and Android</p>

        <v-row justify="center">
          <v-btn rounded depressed class="pl-16 pr-16 mb-16 mt-8">
            download the app
          </v-btn>
        </v-row>
      </v-container> -->

          <div :style="{ background: $vuetify.theme.themes.light.gray }">
            <v-footer padless>
              <v-row justify="center" no-gutters>
                <v-btn
                  v-for="item in footerLinks"
                  :key="item"
                  color="black"
                  text
                  class="my-2"
                  @click="footer(item)"
                >
                  {{ item }}
                </v-btn>
                <v-col class="gray py-4 text-center black--text" cols="12">
                 
                  <strong>©️ 2023 Dalton Technologies, Inc.</strong>
                </v-col>
              </v-row>
            </v-footer>
          </div>
        </template>
      </v-container></v-main
    ></v-app
  >
</template>
 


<script>
import firebase from "firebase/app";
import "firebase/functions";
import VueGtag from "vue-gtag";
export default {
  data: () => ({
    logo: require("@/assets/logo.png"),
    appstore: require("@/assets/appstore.svg"),
    playstore: require("@/assets/playstore.png"),
    header: require("@/assets/header.png"),
    dialog: false,
    emailString: "",
    nameString: "",
    msgString: "",
    infoMessage: "*indicates required field",
    isSending: false,
    appstoreUrl: "https://apps.apple.com/us/app/tappily/id1437063428?ls=1",
    playstoreUrl:
      "https://play.google.com/store/apps/details?id=com.daltontechnologies.tappily&hl=en_AU&gl=US",
    downloadUrl: "",
    platform: "",
    terms: "terms of use",
    privacy: "privacy policy",
    download: "download",
    termsUrl: "https://app.termly.io/document/terms-of-use-for-website/05ca077f-41c4-42d5-b6ae-cb43e977ce11",
    privacyUrl: "https://app.termly.io/document/privacy-policy/5926637c-776f-44d1-9f56-f11f5c5ea361",

    links: [
      { name: "Download", path: "/download" },
      { name: "Contact Us", path: "/contactus" },
    ],

    footerLinks: ["download", "privacy policy", "terms of use"],
    
    faq: [
      {
        question: "What is Tappily?",
        answer:
          "Tappily is a mental health app with fun activities that help promote positive thinking.",
      },

      {
        question: "How can I download Tappily?",
        answer:
          "Tappily is available to download on the App Store and Play Store.",
      },

      {
        question: "Is there a free version of the app?",
        answer:
          "Yes! Tappily is free to download. We have created a generous free version to ensure everyone can enjoy Tappily.",
      },

      {
        question: "How do I cancel my premium membership?",
        answer:
          "You can cancel your Tappily premium subscription at any time in your phone settings. Please remember, uninstalling the app will not cancel your subscription.",
      },
    ],
  }),

  methods: {

footer(linkName){
  if(linkName == this.terms){
window.location.href = this.termsUrl
  }else if(linkName == this.privacy){
window.location.href = this.privacyUrl
  }else{
  this.trackDownload()
  }
},



    trackDownload() {
      let trackDownloadThis = this
      
this.$gtag.event(this.platform, {
        'event_category': "redirect",
        'event_label': "",
        'value': "",
        'event_callback': function(){
          window.location.href = trackDownloadThis.downloadUrl
        }
      })

    },

    sendEmail() {
      //check if required fields are filled in
      let sendEmailThis = this;

      console.log(sendEmailThis.nameString);
      console.log(sendEmailThis.emailString);
      console.log(sendEmailThis.msgString);

      if (
        sendEmailThis.nameString == "" ||
        sendEmailThis.emailString == "" ||
        sendEmailThis.msgString == ""
      ) {
        sendEmailThis.infoMessage = "Please fill out all required fields.";

        return;
      }

      var firebaseConfig = {
        apiKey: "AIzaSyALMnqPq9uH3DC_vx7UmaKydu_grJZksBI",
        authDomain: "tappily-app.firebaseapp.com",
        databaseURL: "https://tappily-app.firebaseio.com",
        projectId: "tappily-app",
        storageBucket: "tappily-app.appspot.com",
        messagingSenderId: "648063601411",
        appId: "1:648063601411:web:413e038268ebf1c5deb736",
        measurementId: "G-P0G6E2DBSQ"
      };
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }

      var sendWelcomeEmail = firebase
        .functions()
        .httpsCallable("sendWelcomeEmail");

      if (this.isSending) {
        return;
      }
      this.isSending = true;

      sendWelcomeEmail({
        name: this.nameString,
        email: this.emailString,
        msg: this.msgString,
      }).then(function (result) {
        sendEmailThis.nameString = "";
        sendEmailThis.emailString = "";
        sendEmailThis.msgString = "";
        sendEmailThis.dialog = false;
        sendEmailThis.isSending = false;
      });
    },
  },

  mounted() {
    function iOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
    function mac() {
      return navigator.platform.indexOf("Mac") > -1;
    }
    if (iOS() || mac()) {
      this.downloadUrl = this.appstoreUrl;
      this.platform = "ios"

    } else {
      this.downloadUrl = this.playstoreUrl;
      this.platform = "android"
    }
  },
};
</script>



<style>
.gradient {
  background: rgb(255, 134, 200);
  background: radial-gradient(
    rgba(255, 220, 94, 0.1) 50%,
    rgba(255, 134, 200, 1) 100%
  );
}

.link {
  text-decoration: none !important;
}

.lineHeight {
  line-height: 40px;
}

.btnBg.v-btn--active::before{
  /* background-color: white; */
  opacity: 0;
}

.dialogBg:focus::before{
  /* background-color: white; */
  opacity: 0  !important;
}



</style>
