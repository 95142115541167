import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes: {
          light: {
            primary: '#ff86c8',
            secondary: '#f6f6f6',
            accent: '#FDD2EA',
            error: '#ff86c8',
            pink: '#ff86c8',
            blue: '#46e3d7',
            yellow: '#ffdc5e',
            black: '#2b2b2b',
            white: '#ffffff',
            gray: '#f6f6f6',
            lightPink: '#FDD2EA',

          },
        },
      },

});
